import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-y-auto" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { style: {"max-height":"85vh","height":"85vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.__("Offers")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_data_table, {
            headers: _ctx.items_headers,
            items: _ctx.pos_offers,
            "single-expand": _ctx.singleExpand,
            expanded: _ctx.expanded,
            "show-expand": "",
            "item-key": "row_id",
            class: "elevation-1",
            "items-per-page": _ctx.itemsPerPage,
            "hide-default-footer": "",
            onMouseover: _cache[0] || (_cache[0] = $event => (_ctx.style = 'cursor: pointer'))
          }, {
            "item.offer_applied": _withCtx(({ item }) => [
              _createVNode(_component_v_checkbox, {
                onClick: $options.forceUpdateItem,
                modelValue: item.offer_applied,
                "onUpdate:modelValue": $event => ((item.offer_applied) = $event),
                disabled: 
              (item.offer == 'Give Product' &&
                !item.give_item &&
                (!_ctx.offer.replace_cheapest_item || !_ctx.offer.replace_item)) ||
              (item.offer == 'Grand Total' &&
                _ctx.discount_percentage_offer_name &&
                _ctx.discount_percentage_offer_name != item.name)
            
              }, null, 8 /* PROPS */, ["onClick", "modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            "expanded-row": _withCtx(({ columns, item }) => [
              _createElementVNode("td", {
                colspan: columns.length
              }, [
                _createVNode(_component_v_row, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    (item.description)
                      ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: "primary--text",
                              innerHTML: $options.handleNewLine(item.description)
                            }, null, 8 /* PROPS */, _hoisted_3)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (item.offer == 'Give Product')
                      ? (_openBlock(), _createBlock(_component_v_col, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_autocomplete, {
                              modelValue: item.give_item,
                              "onUpdate:modelValue": $event => ((item.give_item) = $event),
                              items: $options.get_give_items(item),
                              "item-text": "item_code",
                              variation: "outlined",
                              color: "primary",
                              label: _ctx.__('Give Item'),
                              disabled: 
                    item.apply_type != 'Item Group' ||
                    item.replace_item ||
                    item.replace_cheapest_item
                  
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "items", "label", "disabled"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ], 8 /* PROPS */, _hoisted_2)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "items", "single-expand", "expanded", "items-per-page"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_btn, {
      elevation: "0",
      block: "",
      class: "mt-2",
      variant: "flat",
      color: "warning",
      onClick: $options.back_to_invoice
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick"])
  ], 64 /* STABLE_FRAGMENT */))
}