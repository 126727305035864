import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = { class: "overflow-y-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { style: {"max-height":"85vh","height":"85vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.__("Coupons")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("section", _hoisted_1, [
          _createVNode(_component_v_text_field, {
            variant: "outlined",
            density: "compact",
            color: "primary",
            label: _ctx.__('Coupon'),
            modelValue: _ctx.new_coupon,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.new_coupon) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "success",
                onClick: _cache[0] || (_cache[0] = $event => ($options.add_coupon(_ctx.new_coupon)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.__("add")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_data_table, {
            headers: _ctx.items_headers,
            items: _ctx.posa_coupons,
            "single-expand": _ctx.singleExpand,
            "item-key": "coupon",
            "items-per-page": _ctx.itemsPerPage,
            "hide-default-footer": "",
            onMouseover: _cache[2] || (_cache[2] = $event => (_ctx.style = 'cursor: pointer'))
          }, {
            "item.applied": _withCtx(({ item }) => [
              _createVNode(_component_v_checkbox, {
                modelValue: item.applied,
                "onUpdate:modelValue": $event => ((item.applied) = $event),
                disabled: ""
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "items", "single-expand", "items-per-page"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_btn, {
      elevation: "0",
      block: "",
      class: "mt-2",
      variant: "flat",
      color: "warning",
      onClick: $options.back_to_invoice
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick"])
  ], 64 /* STABLE_FRAGMENT */))
}