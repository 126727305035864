import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toHandlers as _toHandlers, mergeProps as _mergeProps, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-698ff108"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "overflow-y-auto",
  style: {"height":"70vh"}
}
const _hoisted_2 = ["colspan"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")
  const _component_Customer = _resolveComponent("Customer")
  const _component_v_col = _resolveComponent("v-col")
  const _component_TableDropdown = _resolveComponent("TableDropdown")
  const _component_OrderType = _resolveComponent("OrderType")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_date_picker = _resolveComponent("v-date-picker")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_data_table = _resolveComponent("v-data-table")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: $data.cancel_dialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.cancel_dialog) = $event)),
      "max-width": "330"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.__("Cancel Current Invoice ?")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "error",
                  onClick: $options.cancel_invoice
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Cancel")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "warning",
                  onClick: _cache[0] || (_cache[0] = $event => ($data.cancel_dialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_v_card, { style: {"height":"70vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "center",
          class: "items px-2 py-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_Customer)
              ]),
              _: 1 /* STABLE */
            }),
            (!$data.pos_profile.custom_disable_restaurant_mode)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "2",
                  class: "pb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TableDropdown)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (!$data.pos_profile.custom_disable_restaurant_mode)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "2",
                  class: "pb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_OrderType)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($data.pos_profile.posa_allow_sales_order)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "3",
                  class: "pb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      color: "primary",
                      "background-color": "white",
                      items: $data.invoiceTypes,
                      label: _ctx.frappe._('Type'),
                      modelValue: $data.invoiceType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.invoiceType) = $event)),
                      disabled: $data.invoiceType == 'Return'
                    }, null, 8 /* PROPS */, ["items", "label", "modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        (false && $data.pos_profile.posa_use_delivery_charges)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              align: "center",
              class: "items"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "8" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_autocomplete, {
                      clearable: "",
                      outlined: "",
                      color: "primary",
                      label: _ctx.__('Delivery Charges'),
                      modelValue: $data.selcted_delivery_charges,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.selcted_delivery_charges) = $event)),
                      items: $data.delivery_charges,
                      "item-text": "name",
                      "return-object": "",
                      "no-data-text": _ctx.__('Charges not found'),
                      "hide-details": "",
                      filter: $options.deliveryChargesFilter,
                      disabled: _ctx.readonly,
                      onChange: $options.update_delivery_charges
                    }, {
                      item: _withCtx(({ props, item }) => [
                        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              class: "primary--text subtitle-1",
                              innerHTML: item.raw.name
                            }, null, 8 /* PROPS */, ["innerHTML"]),
                            _createVNode(_component_v_list_item_subtitle, {
                              innerHTML: `Rate: ${item.raw.rate}`
                            }, null, 8 /* PROPS */, ["innerHTML"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "filter", "disabled", "onChange"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: "outlined",
                      color: "primary",
                      label: _ctx.__('Delivery Charges Rate'),
                      "hide-details": "",
                      "model-value": _ctx.formatCurrency($data.delivery_charges_rate),
                      prefix: _ctx.currencySymbol($data.pos_profile.currency),
                      disabled: ""
                    }, null, 8 /* PROPS */, ["label", "model-value", "prefix"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($data.pos_profile.posa_allow_change_posting_date)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 1,
              align: "center",
              class: "items"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, {
                      ref: "invoice_posting_date",
                      modelValue: $data.invoice_posting_date,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.invoice_posting_date) = $event)),
                      "close-on-content-click": false,
                      transition: "scale-transition"
                    }, {
                      activator: _withCtx(({ on, props }) => [
                        _createVNode(_component_v_text_field, _mergeProps({
                          modelValue: $data.posting_date,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.posting_date) = $event)),
                          label: _ctx.__('Posting Date'),
                          readonly: "",
                          variant: "outlined",
                          clearable: "",
                          color: "primary",
                          "hide-details": ""
                        }, props, _toHandlers(on)), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_date_picker, {
                          modelValue: $data.posting_date,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.posting_date) = $event)),
                          "no-title": "",
                          scrollable: "",
                          color: "primary",
                          min: _ctx.frappe.datetime.add_days(_ctx.frappe.datetime.now_date(true), -7),
                          max: _ctx.frappe.datetime.add_days(_ctx.frappe.datetime.now_date(true), 7),
                          onInput: _cache[6] || (_cache[6] = $event => ($data.invoice_posting_date = false))
                        }, null, 8 /* PROPS */, ["modelValue", "min", "max"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_data_table, {
            headers: $data.items_headers,
            items: $data.items,
            "single-expand": $data.singleExpand,
            expanded: $data.expanded,
            "show-expand": "",
            "item-key": "id",
            class: "elevation-1",
            "items-per-page": $data.itemsPerPage,
            "hide-default-footer": "",
            onMouseover: _cache[8] || (_cache[8] = $event => (_ctx.style = 'cursor: pointer'))
          }, {
            "item.qty": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.formtFloat(item.qty)), 1 /* TEXT */)
            ]),
            "item.rate": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.currencySymbol($data.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.rate)), 1 /* TEXT */)
            ]),
            "item.amount": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.currencySymbol($data.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(
              _ctx.flt(item.qty, $data.float_precision) *
                _ctx.flt(item.rate, $data.currency_precision)
            )), 1 /* TEXT */)
            ]),
            "item.posa_is_offer": _withCtx(({ item }) => [
              _createVNode(_component_v_checkbox, {
                value: !!item.posa_is_offer || !!item.posa_is_replace,
                disabled: ""
              }, null, 8 /* PROPS */, ["value"])
            ]),
            "expanded-row": _withCtx(({ columns, item }) => [
              _createElementVNode("td", {
                colspan: columns.length
              }, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                          icon: "",
                          color: "error",
                          onClick: _withModifiers($event => ($options.remove_item(item)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-delete")
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_col, { cols: "1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                          icon: "",
                          color: "secondary",
                          onClick: _withModifiers($event => ($options.subtract_one(item)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-minus-circle-outline")
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                          icon: "",
                          color: "secondary",
                          onClick: _withModifiers($event => ($options.add_one(item)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-plus-circle-outline")
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Item Code'),
                          "hide-details": "",
                          modelValue: item.item_code,
                          "onUpdate:modelValue": $event => ((item.item_code) = $event),
                          disabled: ""
                        }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('QTY'),
                          "hide-details": "",
                          "model-value": _ctx.formtFloat(item.qty),
                          onChange: $event => (
                    [
                      _ctx.setFormatedFloat(item, 'qty', null, false, $event),
                      $options.calc_stock_qty(item, $event),
                    ]
                  ),
                          rules: [_ctx.isNumber],
                          disabled: !!item.posa_is_offer || !!item.posa_is_replace
                        }, null, 8 /* PROPS */, ["label", "model-value", "onChange", "rules", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          label: _ctx.__('UOM'),
                          modelValue: item.uom,
                          "onUpdate:modelValue": [$event => ((item.uom) = $event), $event => ($options.calc_uom(item, $event))],
                          items: item.item_uoms,
                          variant: "outlined",
                          "item-title": "uom",
                          "item-value": "uom",
                          "hide-details": "",
                          disabled: _ctx.isSelectDisabled
                        }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue", "items", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Rate'),
                          "hide-details": "",
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          "model-value": _ctx.formatCurrency(item.rate),
                          onChange: $event => (
                    [
                      _ctx.setFormatedCurrency(item, 'rate', null, false, $event),
                      $options.calc_prices(item, $event),
                    ]
                  ),
                          rules: [_ctx.isNumber],
                          id: "rate",
                          disabled: _ctx.isRateDisabled
                        }, null, 8 /* PROPS */, ["label", "prefix", "model-value", "onChange", "rules", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Discount Percentage'),
                          "hide-details": "",
                          "model-value": _ctx.formtFloat(item.discount_percentage),
                          onChange: ($event) => {
                      _ctx.setFormatedCurrency(
                        item,
                        'discount_percentage',
                        null,
                        true,
                        $event
                      );

                      $options.calc_prices(item, $event)
                  }
                  ,
                          rules: [_ctx.isNumber],
                          id: "discount_percentage",
                          disabled: _ctx.isDiscountPercentageDisabled,
                          suffix: "%"
                        }, null, 8 /* PROPS */, ["label", "model-value", "onChange", "rules", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Discount Amount'),
                          "hide-details": "",
                          "model-value": _ctx.formatCurrency(item.discount_amount),
                          rules: [_ctx.isNumber],
                          onChange: $event => (
                    [
                      _ctx.setFormatedCurrency(
                        item,
                        'discount_amount',
                        null,
                        false,
                        $event
                      ),
                      $options.calc_prices(item, $event),
                    ]
                  ),
                          id: "discount_amount",
                          disabled: _ctx.isDiscountAmountDisabled,
                          prefix: _ctx.currencySymbol($data.pos_profile.currency)
                        }, null, 8 /* PROPS */, ["label", "model-value", "rules", "onChange", "disabled", "prefix"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Tax Rate'),
                          "hide-details": "",
                          "model-value": _ctx.formatCurrency(item.tax_rate),
                          onChange: $event => (
                    [
                      _ctx.setFormatedCurrency(
                        item,
                        'tax_rate',
                        null,
                        false,
                        $event
                      ),
                      $options.calc_prices(item, $event),
                    ]
                  ),
                          rules: [_ctx.isNumber],
                          id: "tax_rate",
                          suffix: "%",
                          disabled: _ctx.readonly
                        }, null, 8 /* PROPS */, ["label", "model-value", "onChange", "rules", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Tax Amount'),
                          "hide-details": "",
                          "model-value": _ctx.formatCurrency(item.tax_amount),
                          rules: [_ctx.isNumber],
                          onChange: $event => (
                    [
                      _ctx.setFormatedCurrency(
                        item,
                        'tax_amount',
                        null,
                        false,
                        $event
                      ),
                      $options.calc_prices(item, $event),
                    ]
                  ),
                          id: "tax_amount",
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          disabled: _ctx.readonly
                        }, null, 8 /* PROPS */, ["label", "model-value", "rules", "onChange", "prefix", "disabled"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: "outlined",
                          color: "primary",
                          label: _ctx.__('Amount'),
                          "hide-details": "",
                          "model-value": 
                    _ctx.formatCurrency(
                      _ctx.flt(item.qty, $data.float_precision) *
                        _ctx.flt(item.rate, $data.currency_precision)
                    )
                  ,
                          rules: [_ctx.isNumber],
                          id: "amount",
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          disabled: ""
                        }, null, 8 /* PROPS */, ["label", "model-value", "rules", "prefix"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ], 8 /* PROPS */, _hoisted_2)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["headers", "items", "single-expand", "expanded", "items-per-page"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, { class: "mt-2 p-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "center",
          justify: "end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "8",
              class: "p-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          "model-value": _ctx.formtFloat($options.total_qty),
                          label: _ctx.__('Total Qty'),
                          variant: "outlined",
                          density: "compact",
                          readonly: ""
                        }, null, 8 /* PROPS */, ["model-value", "label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (!$data.pos_profile.posa_use_percentage_discount)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "6",
                          class: "p-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              "model-value": _ctx.formatCurrency($data.discount_amount),
                              onChange: _cache[9] || (_cache[9] = $event => (
                _ctx.setFormatedCurrency(
                  $data.discount_amount,
                  'discount_amount',
                  null,
                  false,
                  $event
                )
              )),
                              rules: [_ctx.isNumber],
                              label: _ctx.__('Additional Discount'),
                              ref: "discount",
                              variant: "outlined",
                              density: "compact",
                              "hide-details": "",
                              prefix: _ctx.currencySymbol($data.pos_profile.currency),
                              disabled: 
                !$data.pos_profile.posa_allow_user_to_edit_additional_discount ||
                $data.discount_percentage_offer_name
                  ? true
                  : false
              
                            }, null, 8 /* PROPS */, ["model-value", "rules", "label", "prefix", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($data.pos_profile.posa_use_percentage_discount)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "6",
                          class: "p-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              "model-value": _ctx.formtFloat($data.additional_discount_percentage),
                              onChange: _cache[10] || (_cache[10] = $event => (
                [
                  _ctx.setFormatedFloat(
                    $data.additional_discount_percentage,
                    'additional_discount_percentage',
                    null,
                    false,
                    $event
                  ),
                  $options.update_discount_umount(),
                ]
              )),
                              rules: [_ctx.isNumber],
                              label: _ctx.__('Additional Discount %'),
                              suffix: "%",
                              ref: "percentage_discount",
                              variant: "outlined",
                              density: "compact",
                              "hide-details": "",
                              disabled: 
                !$data.pos_profile.posa_allow_user_to_edit_additional_discount ||
                $data.discount_percentage_offer_name
                  ? true
                  : false
              
                            }, null, 8 /* PROPS */, ["model-value", "rules", "label", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          "model-value": _ctx.formatCurrency($options.total_items_discount_amount),
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          label: _ctx.__('Items Discounts'),
                          variant: "outlined",
                          density: "compact",
                          readonly: "",
                          "hide-details": ""
                        }, null, 8 /* PROPS */, ["model-value", "prefix", "label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          "model-value": _ctx.formatCurrency($options.subtotal),
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          label: _ctx.__('Total'),
                          variant: "outlined",
                          density: "compact",
                          readonly: ""
                        }, null, 8 /* PROPS */, ["model-value", "prefix", "label"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "4",
              class: "p-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          elevation: "0",
                          color: "warning",
                          dark: "",
                          onClick: $options.get_draft_invoices
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Held")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    ($data.pos_profile.custom_allow_select_sales_order === 1)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          class: "p-1",
                          cols: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              elevation: "0",
                              block: "",
                              color: "info",
                              dark: "",
                              onClick: $options.get_draft_orders
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.__("Select S.O")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          elevation: "0",
                          block: "",
                          class: _normalizeClass({ 'disable-events': !$data.pos_profile.posa_allow_return }),
                          color: "secondary",
                          dark: "",
                          onClick: $options.open_returns
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Return")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["class", "onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          elevation: "0",
                          block: "",
                          color: "error",
                          dark: "",
                          onClick: _cache[11] || (_cache[11] = $event => ($data.cancel_dialog = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Cancel")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "p-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          elevation: "0",
                          block: "",
                          color: "accent",
                          dark: "",
                          onClick: $options.new_invoice
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Save/New")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { class: "p-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          elevation: "0",
                          color: "success",
                          onClick: $options.show_payment,
                          dark: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("PAY")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    ($data.pos_profile.posa_allow_print_draft_invoices)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          class: "p-1",
                          cols: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              block: "",
                              elevation: "0",
                              color: "primary",
                              onClick: $options.print_draft_invoice,
                              dark: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.__("Print Draft")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}