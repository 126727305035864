import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline primary--text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_data_table = _resolveComponent("v-data-table")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, { justify: "center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.closingDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.closingDialog) = $event)),
        "max-width": "900px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__('Closing POS Shift')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            class: "pa-1"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_data_table, {
                                headers: _ctx.headers,
                                items: _ctx.dialog_data.payment_reconciliation,
                                "item-key": "mode_of_payment",
                                class: "elevation-1",
                                "items-per-page": _ctx.itemsPerPage,
                                "hide-default-footer": ""
                              }, {
                                "item.closing_amount": _withCtx(({ item }) => [
                                  _createVNode(_component_v_text_field, {
                                    modelValue: item.closing_amount,
                                    "onUpdate:modelValue": $event => ((item.closing_amount) = $event),
                                    rules: [_ctx.max25chars],
                                    "single-line": "",
                                    counter: "",
                                    type: "number",
                                    "hide-details": "",
                                    dense: ""
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "rules"])
                                ]),
                                "item.difference": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString((item.difference = _ctx.formatCurrency(
                          item.expected_amount - item.closing_amount
                        ))), 1 /* TEXT */)
                                ]),
                                "item.opening_amount": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.opening_amount)), 1 /* TEXT */)
                                ]),
                                "item.expected_amount": _withCtx(({ item }) => [
                                  _createTextVNode(_toDisplayString(_ctx.currencySymbol(_ctx.pos_profile.currency)) + " " + _toDisplayString(_ctx.formatCurrency(item.expected_amount)), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["headers", "items", "items-per-page"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    dark: "",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__('Close')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    dark: "",
                    onClick: $options.submit_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__('Submit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}