import { resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_UpdateCustomer = _resolveComponent("UpdateCustomer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_autocomplete, {
      class: "p-3",
      dense: "",
      clearable: "",
      "auto-select-first": "",
      variant: "outlined",
      color: "primary",
      label: _ctx.__('Customer'),
      modelValue: _ctx.customer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customer) = $event)),
      items: _ctx.customers,
      "item-title": "customer_name",
      "item-key": "name",
      "no-data-text": _ctx.__('Customer not found'),
      disabled: _ctx.readonly,
      "append-icon": "mdi-plus",
      "onClick:append": $options.new_customer,
      "prepend-inner-icon": "mdi-account-edit",
      "onClick:prependInner": $options.edit_customer
    }, {
      item: _withCtx(({ props, item }) => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
              default: _withCtx(() => [
                (item.raw.customer_name != item.raw.name)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 0,
                      textContent: _toDisplayString(`ID: ${item.raw.name}`)
                    }, null, 8 /* PROPS */, ["textContent"]))
                  : _createCommentVNode("v-if", true),
                (item.raw.tax_id)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 1,
                      textContent: _toDisplayString(`TAX ID: ${item.raw.tax_id}`)
                    }, null, 8 /* PROPS */, ["textContent"]))
                  : _createCommentVNode("v-if", true),
                (item.raw.email_id)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 2,
                      textContent: _toDisplayString(`Email: ${item.raw.email_id}`)
                    }, null, 8 /* PROPS */, ["textContent"]))
                  : _createCommentVNode("v-if", true),
                (item.raw.mobile_no)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 3,
                      textContent: _toDisplayString(`Mobile No: ${item.raw.mobile_no}`)
                    }, null, 8 /* PROPS */, ["textContent"]))
                  : _createCommentVNode("v-if", true),
                (item.raw.primary_address)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 4,
                      textContent: _toDisplayString(`Primary Address: ${item.raw.primary_address}`)
                    }, null, 8 /* PROPS */, ["textContent"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "disabled", "onClick:append", "onClick:prependInner"]),
    _createVNode(_component_UpdateCustomer)
  ], 64 /* STABLE_FRAGMENT */))
}