import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fluid: "",
  class: "mt-2"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TableUI = _resolveComponent("TableUI")
  const _component_ClosingDialog = _resolveComponent("ClosingDialog")
  const _component_Drafts = _resolveComponent("Drafts")
  const _component_SalesOrders = _resolveComponent("SalesOrders")
  const _component_Returns = _resolveComponent("Returns")
  const _component_NewAddress = _resolveComponent("NewAddress")
  const _component_MpesaPayments = _resolveComponent("MpesaPayments")
  const _component_Variants = _resolveComponent("Variants")
  const _component_OpeningDialog = _resolveComponent("OpeningDialog")
  const _component_ItemsSelector = _resolveComponent("ItemsSelector")
  const _component_v_col = _resolveComponent("v-col")
  const _component_PosOffers = _resolveComponent("PosOffers")
  const _component_PosCoupons = _resolveComponent("PosCoupons")
  const _component_Payments = _resolveComponent("Payments")
  const _component_Invoice = _resolveComponent("Invoice")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.dialog && _ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_TableUI, { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_ClosingDialog),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_Drafts, { key: 1 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_SalesOrders, { key: 2 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_Returns, { key: 3 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_NewAddress, { key: 4 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_MpesaPayments, { key: 5 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.is_table_view)
      ? (_openBlock(), _createBlock(_component_Variants, { key: 6 }))
      : _createCommentVNode("v-if", true),
    (_ctx.dialog)
      ? (_openBlock(), _createBlock(_component_OpeningDialog, {
          key: 7,
          dialog: _ctx.dialog
        }, null, 8 /* PROPS */, ["dialog"]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_v_col, {
          xl: "5",
          lg: "5",
          md: "5",
          sm: "5",
          cols: "12",
          class: "pos pr-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ItemsSelector)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, !_ctx.payment && !_ctx.offers && !_ctx.coupons]
        ]),
        _withDirectives(_createVNode(_component_v_col, {
          xl: "5",
          lg: "5",
          md: "5",
          sm: "5",
          cols: "12",
          class: "pos pr-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PosOffers)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.offers]
        ]),
        _withDirectives(_createVNode(_component_v_col, {
          xl: "5",
          lg: "5",
          md: "5",
          sm: "5",
          cols: "12",
          class: "pos pr-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PosCoupons)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.coupons]
        ]),
        _withDirectives(_createVNode(_component_v_col, {
          xl: "5",
          lg: "5",
          md: "5",
          sm: "5",
          cols: "12",
          class: "pos pr-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Payments)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.payment]
        ]),
        _createVNode(_component_v_col, {
          xl: "7",
          lg: "7",
          md: "7",
          sm: "7",
          cols: "12",
          class: "pos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Invoice)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.dialog && !_ctx.is_table_view]
    ])
  ]))
}