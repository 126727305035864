import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_date_picker = _resolveComponent("v-date-picker")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.customerDialog,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.customerDialog) = $event)),
        "max-width": "600",
        "onClick:outside": $options.clear_customer
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "px-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "py-4 p-0" }, {
                default: _withCtx(() => [
                  (_ctx.customer_id)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.__("Update Customer")), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.__("Create Customer")), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.__('Customer Name') + ' *',
                        "background-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.customer_name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customer_name) = $event))
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.__('Tax ID'),
                        "background-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.tax_id,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.tax_id) = $event))
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.__('Mobile No'),
                        "background-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.mobile_no,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.mobile_no) = $event))
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.__('Email Id'),
                        "background-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.email_id,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.email_id) = $event))
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        dense: "",
                        variant: "outlined",
                        label: "Gender",
                        items: _ctx.genders,
                        modelValue: _ctx.gender,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.gender) = $event))
                      }, null, 8 /* PROPS */, ["items", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        variant: "outlined",
                        color: "primary",
                        label: _ctx.__('Referral Code'),
                        "background-color": "white",
                        "hide-details": "",
                        modelValue: _ctx.referral_code,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.referral_code) = $event))
                      }, null, 8 /* PROPS */, ["label", "modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        ref: "birthday_menu",
                        modelValue: _ctx.birthday_menu,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.birthday_menu) = $event)),
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        dense: "",
                        variant: "outlined"
                      }, {
                        activator: _withCtx(({ on, props }) => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.birthday,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.birthday) = $event)),
                            label: _ctx.__('Birthday'),
                            readonly: "",
                            dense: "",
                            variant: "outlined",
                            clearable: "",
                            "hide-details": ""
                          }, props, _toHandlers(on), { color: "primary" }), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_date_picker, {
                            modelValue: _ctx.birthday,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.birthday) = $event)),
                            color: "primary",
                            "no-title": "",
                            scrollable: "",
                            max: _ctx.frappe.datetime.now_date(),
                            onInput: _cache[8] || (_cache[8] = $event => (_ctx.birthday_menu = false))
                          }, null, 8 /* PROPS */, ["modelValue", "max"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_autocomplete, {
                        clearable: "",
                        dense: "",
                        variant: "outlined",
                        "auto-select-first": "",
                        color: "primary",
                        label: _ctx.__('Customer Group') + ' *',
                        modelValue: _ctx.group,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.group) = $event)),
                        items: _ctx.groups,
                        "background-color": "white",
                        "no-data-text": _ctx.__('Group not found'),
                        "hide-details": "",
                        required: ""
                      }, null, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "p-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_autocomplete, {
                        clearable: "",
                        dense: "",
                        variant: "outlined",
                        "auto-select-first": "",
                        color: "primary",
                        label: _ctx.__('Territory') + ' *',
                        modelValue: _ctx.territory,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.territory) = $event)),
                        items: _ctx.territorys,
                        "background-color": "white",
                        "no-data-text": _ctx.__('Territory not found'),
                        "hide-details": "",
                        required: ""
                      }, null, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (_ctx.loyalty_program)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "6",
                        class: "p-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.loyalty_program,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.loyalty_program) = $event)),
                            label: _ctx.__('Loyalty Program'),
                            dense: "",
                            variant: "outlined",
                            readonly: "",
                            "hide-details": ""
                          }, null, 8 /* PROPS */, ["modelValue", "label"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (_ctx.loyalty_points)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 1,
                        cols: "6",
                        class: "p-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.loyalty_points,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.loyalty_points) = $event)),
                            label: _ctx.__('Loyalty Points'),
                            dense: "",
                            variant: "outlined",
                            readonly: "",
                            "hide-details": ""
                          }, null, 8 /* PROPS */, ["modelValue", "label"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_actions, { class: "py-4 p-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "error",
                    dark: "",
                    onClick: $options.close_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Close")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    dark: "",
                    onClick: $options.submit_dialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.__("Submit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "onClick:outside"])
    ]),
    _: 1 /* STABLE */
  }))
}