<template>
  <v-row justify="center">
    <v-dialog v-model="invoicesDialog" max-width="800px" min-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            __("Select Return Invoice")
          }}</span>
        </v-card-title>
        <v-container>
          <v-row class="mb-4">
            <v-text-field
              color="primary"
              :label="__('Invoice ID')"
              background-color="white"
              hide-details
              v-model="invoice_name"
              dense
              clearable
              class="mx-4"
            ></v-text-field>
            <v-btn
              text
              class="ml-2"
              color="primary"
              dark
              @click="search_invoices"
              >{{ __("Search") }}</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-1" v-if="dialog_data">
              <v-data-table
                :headers="headers"
                :items="dialog_data"
                item-value="name"
                class="elevation-1"
                :single-select="singleSelect"
                show-select
                v-model="selected"
              >
                <template v-slot:item.grand_total="{ item }">
                  {{ currencySymbol(item.currency) }}
                  {{ formatCurrency(item.grand_total) }}</template
                >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn color="error mx-2" dark @click="close_dialog">Close</v-btn>
          <v-btn
            v-if="selected.length"
            color="success"
            dark
            @click="submit_dialog"
            >{{ __("Select") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "../../bus";
import format from "../../format";
export default {
  mixins: [format],
  data: () => ({
    invoicesDialog: false,
    singleSelect: true,
    selected: [],
    dialog_data: "",
    company: "",
    invoice_name: "",
    headers: [
      {
        title: __("Customer"),
        key: "customer",
        align: "start",
        sortable: true,
      },
      {
        title: __("Date"),
        align: "start",
        sortable: true,
        key: "posting_date",
      },
      {
        title: __("Invoice"),
        key: "name",
        align: "start",
        sortable: true,
      },
      {
        title: __("Amount"),
        key: "grand_total",
        align: "end",
        sortable: false,
      },
    ],
  }),
  watch: {},
  methods: {
    close_dialog() {
      this.invoicesDialog = false;
    },
    search_invoices_by_enter(e) {
      if (e.keyCode === 13) {
        this.search_invoices();
      }
    },
    search_invoices() {
      const vm = this;
      frappe.call({
        method: "posawesome.posawesome.api.posapp.search_invoices_for_return",
        args: {
          invoice_name: vm.invoice_name,
          company: vm.company,
        },
        async: false,
        callback: function (r) {
          if (r.message) {
            vm.dialog_data = r.message;
          }
        },
      });
    },
    submit_dialog() {
      const vm = this

      if (this.selected.length > 0) {
        frappe.call({
          method: "posawesome.posawesome.api.posapp.prepare_invoice_for_return",
          args: {
            invoice_name: this.selected[0]
          },
          async: false,
          callback: function (r) {
            if (r.message) {
              eventBus.emit('load_invoice', r.message);
              vm.invoicesDialog = false;
            }
          },
        });
      }
    },
  },
  created: function () {
    eventBus.on("open_returns", (data) => {
      this.invoicesDialog = true;
      this.company = data;
      this.invoice_name = "";
      this.dialog_data = "";
      this.selected = [];
    });
  },
};
</script>
